import React, { Component } from "react";
import BlogCard from "../elements/BlogCard";

class Blog extends Component {
  constructor(props) {
    super();
    this.state = { articles: [] };
  }

  componentDidMount() {
    const devTo = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@danielachvzm";

    fetch(devTo)
      .then(response => {
        return response.json();
      })
      .then(data => {
        let articles1 = [];
        let articles2 = [];
        let articles3 = [];
        data.items.forEach((element, index) => {
          if (index < 3) {
            articles1.push(
              <div className="column" key={index}>
                <BlogCard
                  key={index}
                  title={element.title}
                  url={element.guid}
                  image={element.thumbnail}
                />
              </div>
            );
          }
          if (index > 2 && index < 6) {
            articles2.push(
              <div className="column" key={index}>
                <BlogCard
                  key={index}
                  title={element.title}
                  url={element.guid}
                  image={element.thumbnail}
                />
              </div>
            );
          }
          if (index > 5 && index < 9) {
            articles3.push(
              <div className="column" key={index}>
                <BlogCard
                  key={index}
                  title={element.title}
                  url={element.guid}
                  image={element.thumbnail}
                />
              </div>
            );
          }
        });
        var offset = 4 - data.length;
        for (var i = 0; i < offset; i++) {
          articles1.push(<div className="column"></div>);
          articles2.push(<div className="column"></div>);
        }
        this.setState({ articles1: articles1 });
        this.setState({ articles2: articles2 });
        this.setState({ articles3: articles3 });
      });
  }

  render() {
    return (
      <section className="section" id="blog">
        <div className="container">
          <h2 className="subtitle is-3">My latest blog posts</h2>
          <div className="columns">{this.state.articles1}</div>
          <div className="columns">{this.state.articles2}</div>
          <div className="columns">{this.state.articles3}</div>
        </div>
      </section>
    );
  }
}

export default Blog;
